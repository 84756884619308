
import { Component, Vue } from 'vue-property-decorator';
import { DeepReadonly } from 'ts-essentials';
import { Location } from 'vue-router';
import FullScreenForm from '@/components/FullScreenForm.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import GSelect from '@/components/gsk-components/GskSelect.vue';
import { ProjectsModule } from '@/store/modules/projects.module';
import { ProjectDetailsModule } from '@/store/modules/project-details.module';
import { ListingsModule as ListingCatalogModule } from '@/store/modules/listings.module';
import { AUTH_SRC, Platforms, RouteNames, TextfieldInfo } from '@/constants';
import { addServiceToProject, createProject, getBudgetingSourceList, Projects } from '@/api/projects.api';
import GRadioGroup from '@/components/gsk-components/GskRadioGroup.vue';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import { openErrorSnackbar, openSnackbar } from '@/utils/components';
import {
  RadioField,
  SelectField,
  SelectOption,
  SelectValue,
  TextField,
  AutoCompleteField 
} from '@/components/form/form.types';
import { ListingEnvironment } from '@/api/listings.api';
import { addAnalyticsRouteParams, ClickData } from '@/analytics';
import GAnalytics from '@/components/GAnalytics';
import { EnumsModule } from '@/store/modules/enums.module';
import ValidatedForm from '@/components/form/ValidatedForm.vue';
import { FeatureFlagsModule } from '@/store/modules/feature-flags.module';
import invariant from 'ts-invariant';
import { ProjectListProject, ConnectingProjectList } from '@/types/projects.types';
import PlatformTypeChip from '@/components/PlatformTypeChip.vue';
import { cleanOutSpecialCharAndTags } from '@/utils/routing';
import { get, omit } from 'lodash';
import { ProcessEnvsModule } from '@/store/modules/process-envs.module';

interface NewProjectForm {
  projectName: string;
  projectDescription: string;
  businessUnit: string;
  businessSubUnit?: string;
  budgetingSource?: Projects.BudgetingSource | Record<string, string>;
  requestedServiceId: number;
  api: boolean;
  oauthType?: string;
}

enum ProjectTypes {
  New = 'NEW',
  Existing = 'EXISTING',
}

type ProjectFields = [
  TextField<'name'>,
  TextField<'description'>,
  SelectField<'bu'>,
  TextField<'sbu'>,
  AutoCompleteField<'budgetingSource'>,
  RadioField<'platformType'>,
  RadioField<'oauthType'>,
];

@Component({
  components: {
    FullScreenForm,
    GButton,
    GSelect,
    GRadioGroup,
    GTextfield,
    GAnalytics,
    ValidatedForm,
    PlatformTypeChip
  },
})
export default class ProjectConnectView extends Vue {
  private loading = true;
  public listingName: string = ListingCatalogModule.currentListing.listingName;
  private projectId: string | number = '';
  private ready = false;
  private serviceEnvs: ListingEnvironment[] = [];
  private serviceEnv: SelectValue = '';
  public platformDetails: any = ListingCatalogModule.currentListing.platform;

  projectTypeOptions: SelectOption[] = [
    {
      label: 'Existing',
      value: ProjectTypes.Existing,
    },
    {
      label: 'New',
      value: ProjectTypes.New,
    },
  ];
  private projectType: SelectValue = this.projectTypeOptions[0].value;

  async getList(val:number){
    await Promise.all([
        ProjectsModule.getConnectingServiceProjects({
          serviceId: Number(val)
        })
      ]);
      
    if(ProjectsModule.filteredProjects.length === 0 && this.projectTypeOptions.length === 2){
      this.projectTypeOptions.shift();
    }
    else{
      if(this.projectTypeOptions.length === 1){
        this.projectTypeOptions.unshift({
          label: 'Existing',
          value: ProjectTypes.Existing,
        });
      }
      this.projectType = this.projectTypeOptions[0].value;
      this.selectedProject();
    }
  }

  selectedProject(){
    // preselect the last viewed project
    const selectedProjectId = this.filteredProjects.find(projectOption => projectOption.value === ProjectDetailsModule.projectDetails.projectId);
    if(selectedProjectId){
      this.projectId = selectedProjectId.value;
    }
    else{
      //reset the select to initial value
      const p = ProjectsModule.filteredProjects[0];
      if (p) {
        this.projectId = p.projectId;
      } else {
        this.projectTypeOptions.shift();
      }
    }
  }

  get filteredProjects() {
    const mapFn = (project: ConnectingProjectList) => {
      return {
        key: project.projectId,
        value: project.projectId,
        label: project.projectName,
      };
    }
    
    return ProjectsModule.filteredProjects.map(project => mapFn(project));
  }
  
  get projectName() {
    return this.projectForm[0].value;
  }
  get projectDescription() {
    return this.projectForm[1].value;
  }
  get projectBusinessUnit() {
    return this.projectForm[2].value;
  }
  get projectBusinessSubUnit() {
    return this.projectForm[3].value;
  }
  get projectBudgetingSource() {
    return this.projectForm[4].value;
  }
  
  get projectOAuthTypeMnemonic() {
    let projectOAuthType = AUTH_SRC.PING_OAUTH.mnemonic;

    if (FeatureFlagsModule.azureADEnabled) {
      if (this.svcOAuthType.mnemonic === AUTH_SRC.API_KEY.mnemonic) {
        let authDisplayValue = this.projectForm[6].value;
        const refList = AUTH_SRC;
        Object.keys(AUTH_SRC).forEach(key => {
          const def = refList[key];
          if (def.readableName === authDisplayValue) {
            projectOAuthType = def.mnemonic;
          }
        });
      } else if (this.svcOAuthType.mnemonic === AUTH_SRC.AD_OAUTH.mnemonic) {
        projectOAuthType = AUTH_SRC.AD_OAUTH.mnemonic;
      }
    }
    return projectOAuthType;
  }

  get svcOAuthType() {
    let ref = AUTH_SRC['PING_OAUTH'];
    if (this.serviceEnv) {
      const svc: ListingEnvironment | undefined = this.serviceEnvs.find(
        env => env.serviceId === parseInt(this.serviceEnv, 10),
      );
      if (svc) {
        const refList = AUTH_SRC;
        Object.keys(AUTH_SRC).forEach(key => {
          const def = refList[key];
          if (def.apiRegistrationEnum === svc.authenticationType) {
            ref = refList[key];
          }
        });
      }
    }
    return {
      mnemonic: ref.mnemonic,
      readableName: ref.readableName,
      shortName: ref.readableName,
      apiRegistrationEnum: ref.apiRegistrationEnum,
      servicePropEnumVal: ref.servicePropEnumVal,
    };
  }

  isProjectAuthTypePreset(): boolean {
    let preset = true;

    if (FeatureFlagsModule.azureADEnabled) {
      const svcAuthType = this.svcOAuthType;
      preset = svcAuthType.mnemonic !== 'API_KEY';
    }
    return preset;
  }

  setDefaultAuthSource(authVal: string) {
    const oAuthSelected = Object.keys(Platforms).find(
      currentKey => Platforms[currentKey].readableName === authVal,
    ) as any;
    const key = Platforms[oAuthSelected].platformVal;
    this.projectForm[6].options = Object.keys(AUTH_SRC)
      .filter(currentKey => currentKey == 'PING_OAUTH' || currentKey === key)
      .map(currentKey => AUTH_SRC[currentKey].readableName);
    this.projectForm[6].value = AUTH_SRC['PING_OAUTH'].readableName;
  }


  updateFormData(field: any, value: any, newVal: any) {
    if(value.key === 'platformType') {
      this.setDefaultAuthSource(newVal)
    }
  }
  get budgetingSourceTooltipMessage() {
    return ProcessEnvsModule.processEnvs.budgetingSourceTooltipText;
  }

  projectForm: ProjectFields = [
    {
      type: 'text',
      key: 'name',
      label: TextfieldInfo.nameLabel,
      value: '',
      attrs: {
        placeholder: TextfieldInfo.namePlaceholder,
        maxlength: '50',
        'data-testid': 'connect-project-input-name',
      },
      required: true,
      validation: {
        rules: {
          required: true,
        },
      },
    },
    {
      type: 'long-text',
      key: 'description',
      label: TextfieldInfo.descriptionLabel,
      value: '',
      attrs: {
        placeholder: TextfieldInfo.descriptionPlaceholder,
        maxlength: '255',
        'data-testid': 'connect-project-input-description',
      },
      // helpText: 'Enter a short description of your project',
      required: true,
      validation: {
        rules: {
          required: true,
        },
      },
    },
    {
      type: 'select',
      key: 'bu',
      label: 'Business Unit',
      value: '',
      options: EnumsModule.businessUnitFormOptions,
      required: true,
      validation: {
        rules: {
          required: true,
        },
      },
    },
    {
      type: 'text',
      key: 'sbu',
      label: 'Business Sub-unit',
      value: '',
      attrs: {
        placeholder: 'Enter a business sub-unit',
        maxLength: '100'
      },
    },
    {
      type: 'autocomplete',
      key: 'budgetingSource',
      label:'',
      customLabel: 'Budgeting Source (Cost Center or WBS for Capex Projects)',
      customLabelWithIcon: 'question_mark',
      customLabelIconHoverText :  this.budgetingSourceTooltipMessage,
      value: {
        label: '',
        value: ''
      },
      hide:!FeatureFlagsModule.budgetingSourceEnabled,
      choices: [],
      async getChoices(query: string) {
        const choices = await getBudgetingSourceList(query);
        return choices.data.map(res => ({
          label: `${res.id} - ${res.name}`,
          value: res.id,
          name: res.name,
          person: res.person,
          type: res.type,
          id: res.id,
        }));
      },
      attrs: {
        async: true,
        placeholder: 'Type a keyword to search',
      }
    },
    {
      type: 'radio',
      options: Object.keys(Platforms)
        .map(currentKey => Platforms[currentKey].readableName),
      label: 'Platform',
      isPlatform: true,
      attrs: {
        disabled: !FeatureFlagsModule.gcpEnabled
      },
      value: Platforms['AZURE'].readableName,
      hide: !FeatureFlagsModule.azureADEnabled || !FeatureFlagsModule.pingEnabled,
      key: 'platformType',
      inline: false,
      leadingHeader: '',
      required: true,
      validation: {
        rules: {
          required: true,
        },
      },
    },
    {
      type: 'radio',
      options: Object.keys(AUTH_SRC)
        .filter(currentKey => currentKey !== 'API_KEY')
        .map(currentKey => AUTH_SRC[currentKey].readableName),
      label: 'OAuth Source',
      value: 'PingFederate (OAuth)',
      hideIf: this.isProjectAuthTypePreset,
      key: 'oauthType',
      inline: false,
      leadingHeader: '',
      required: true,
      validation: {
        rules: {
          required: true,
        },
      },
    },
  ];

  back() {
    this.$router.safeBack(this.listingPath);
  }

  get listingPath(): Location {
    return {
      name: RouteNames.ListingDetails,
      params: {
        listingId: this.$route.params.listingId,
      },
    };
  }

  get listingId() {
    return this.$route.params.id;
  }
  get listingText() {
    return `Select the ${this.listingName} environment`;
  }

  get serviceEnvOptions(): DeepReadonly<SelectOption[]> {
    return this.serviceEnvs.length
      ? this.serviceEnvs.map(env => {
          let version =
            this.serviceEnvs.filter(tenv => tenv.environmentName === env.environmentName).length >=
            2
              ? ' v' + env.versionId
              : '';
          return {
            label: env.environmentName.concat(version.toString()),
            value: env.serviceId.toString(),
          };
        })
      : [{ value: '', label: '' }];
  }

  get snackbarLabel() {
    return this.isExistingProject ? 'Service connected' : 'New project created';
  }

  get snackbarError() {
    return this.isExistingProject
      ? 'Error: Could not connect service'
      : 'Error: could not create new project';
  }

  get projects() {
    const preset = this.isProjectAuthTypePreset();
    const mapFn = (project: ProjectListProject) => {
      return {
        key: project.projectId,
        value: project.projectId,
        label: project.projectName,
      };
    }
    
    if(preset) {
      const serviceEnv = this.serviceEnvs.find(serviceEnv => serviceEnv.serviceId === +this.serviceEnv);
      return ProjectsModule.projects.filter(project => {
          return !!project.environments.find(env => env.environmentName === serviceEnv?.environmentName && 
          env.authType === this.svcOAuthType?.mnemonic)
        }).map(project => mapFn(project));
    }
    
    return ProjectsModule.projects.map(project => mapFn(project));
  }

  async getNewProjectDetails(data: NewProjectForm): Promise<number> {
    const {
      data: { projectId },
    } = await createProject(data);
    return projectId;
  }

  get formIsValid() {
    if (this.isExistingProject) {
      return true;
    }
    if(this.projectName.length > 50 || this.projectDescription.length > 255 || this.projectBusinessSubUnit.length > 100){
      return false;
    }
    return !!this.projectName && !!this.projectDescription && !!this.projectBusinessUnit;
  }

  get newProjectFormData(): NewProjectForm {
    return {
      projectName: cleanOutSpecialCharAndTags(this.projectName),
      projectDescription: this.projectDescription,
      businessUnit: this.projectBusinessUnit,
      businessSubUnit: this.projectBusinessSubUnit || undefined, // convert empty string
      ...(get(this.projectBudgetingSource,'value','')!=='' && { budgetingSource: omit(get(this.projectForm[4],'value',{}),['label','value']) }),
      requestedServiceId: +this.serviceEnv,
      oauthType: this.projectOAuthTypeMnemonic,
      api: true,
    };
  }

  get selectedProjectName(): string | null {
    if (this.isExistingProject) {
      return (
        this.filteredProjects.find(projectOption => projectOption.value === this.projectId)?.label ?? null
      );
    }
    return this.projectName;
  }
  get connectAnalytics(): ClickData {
    return {
      clickTarget: 'connect-form-connect-button',
      projectType: this.isExistingProject ? ProjectTypes.Existing : ProjectTypes.New,
      projectName: this.selectedProjectName,
      serviceName: this.listingName,
      serviceEnv: this.serviceEnvOptions.find(so => so.value === this.serviceEnv)?.label,
      serviceId: +this.serviceEnv,
    };
  }

  async save(sendAnalytics: () => void): Promise<void> {
    let id;
    this.loading = true;
    sendAnalytics();
    try {
      if (!this.isExistingProject) {
        ProjectDetailsModule.setEmptyProject();
        id = await this.getNewProjectDetails(this.newProjectFormData).catch(error => {
          throw new Error(error.response?.data?.message ?? error);
        });
      } else {
        await this.addService(+this.serviceEnv).catch(error => {
          throw new Error(error.response?.data?.error ?? error);
        });
        await ProjectDetailsModule.baseGetProject(this.projectId);
        id = this.projectId;
      }
    } catch (e) {
      this.$log('error', e);
      openErrorSnackbar.call(this, e.message);
      return;
    } finally {
      this.loading = false;
    }

    openSnackbar.call(this, this.snackbarLabel);
    const data = this.connectAnalytics;
    const env = await ProjectDetailsModule.defaultEnv;
    if (env) {
      this.$router
        .push({
          name: RouteNames.ProjectEnvDetails,
          query: {success: '1'},
          params: addAnalyticsRouteParams(
            { id: id + '', section: 'services', env: env.projectEnvironmentId.toString() },
            {
              projectName: data.projectName,
              projectType: data.projectType,
            },
          ),
        })
        .catch(e => this.$logger.warn(e));
    } else {
      this.$router
        .push({
          name: RouteNames.ProjectDetails,
          params: addAnalyticsRouteParams(
            { id: id + '' },
            {
              projectName: data.projectName,
              projectType: data.projectType,
            },
          ),
        })
        .catch(e => this.$logger.warn(e));
    }
  }

  async created() {
    if (ListingCatalogModule.noCurrentListing) {
      // when user navigates directly to this page
      await Promise.all([
        // handle 404 for listing deets
        ListingCatalogModule.getListingDetails(+this.$route.params.listingId),
        ListingCatalogModule.getListingEnvironments(+this.$route.params.listingId),
      ]);
    } else {
      // when linked here from service details page
      await Promise.all([
        ListingCatalogModule.getListingEnvironments(+this.$route.params.listingId),
      ]);
    }

    this.serviceEnvs = ListingCatalogModule.environments;
    this.serviceEnv = this.serviceEnvOptions[0].value;
    await Promise.all([
        ProjectsModule.getConnectingServiceProjects({
          serviceId: Number(this.serviceEnv)
        })
      ]);
    
    this.selectedProject();
    this.setDefaultAuthSource(this.projectForm[5].value);
    this.loading = false;
    this.ready = true;
  }

  get isExistingProject() {
    this.projectType =
      // eslint-disable-next-line max-len
      ProjectsModule.filteredProjects.length === 0 && !this.loading ? ProjectTypes.New : this.projectType;
    return this.projectType === ProjectTypes.Existing;
  }

  async addService(serviceEnvId: number) {
    const projectId = this.projectId;
    await ProjectDetailsModule.getProject(this.projectId);

    let projectEnvId = -1;
    const env = ProjectDetailsModule.devEnv;
    if (env) {
      projectEnvId = env.projectEnvironmentId;
    }
    const oauthType = this.projectOAuthTypeMnemonic;
    invariant('PING_OAUTH' === oauthType || 'AD_OAUTH' === oauthType, 'invalid oauth type');

    await addServiceToProject({ projectId, projectEnvId, serviceEnvId, oauthType });
  }

  get platformName() {
    return this.platformDetails ? this.platformDetails.displayName : 'Azure';
  }
  get platformProjectLabel() {
    const oAuthSource = this.svcOAuthType.mnemonic;    
    if (oAuthSource === "PING_OAUTH" || oAuthSource === "AD_OAUTH") {
      return `Select one of your ${this.platformName} projects which uses ${this.svcOAuthType.readableName}`;
    } else {
      return `Select one of your ${this.platformName} projects`;
    }
  }
}
